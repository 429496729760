import React from 'react';


const App5 = () => {

    const enrollmentInformation = [
        {
            title: 'Бакалавриат',
            links: [
                {
                    title: 'Сведения о зачислении от 29.07.2024 № 906 (Правительственная квота)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_906.pdf'
                },
                {
                    title: 'Сведения о зачислении от 29.07.2024 № 908 (Особая квота. Очно)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_908.pdf'
                },
                {
                    title: 'Сведения о зачислении от 29.07.2024 № 910 (Особая квота. Заочно)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_910.pdf'
                },
                {
                    title: 'Сведения о зачислении от 29.07.2024 № 912 (Отдельная квота. Очно)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_912.pdf'
                },
                {
                    title: 'Сведения о зачислении от 29.07.2024 № 913 (Отдельная квота. Заочно)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_913.pdf'
                },
                {
                    title: 'Сведения о зачислении от 07.08.2024 № 956 (Очно. Бюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_956.pdf'
                },
                {
                    title: 'Сведения о зачислении от 07.08.2024 № 957 (Заочно. Бюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_957.pdf'
                },
                {
                    title: 'Сведения о зачислении от 14.08.2024 № 965 (Очно. Бюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_965.pdf'
                },
                {
                    title: 'Сведения о зачислении от 29.08.2024 № 987 (Очно. Бюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_987.pdf'
                },
                {
                    title: 'Сведения о зачислении от 30.08.2024 № 999 (Очно. Внебюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_999.pdf'
                },
                {
                    title: 'Сведения о зачислении от 30.08.2024 № 1000 (Очно-заочно. Внебюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_1000.pdf'
                },
                {
                    title: 'Сведения о зачислении от 30.08.2024 № 1001 (Заочно. Внебюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_1001.pdf'
                },
                {
                    title: 'Сведения о зачислении от 30.09.2024 № 1182 (Очно. Внебюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_1182.pdf'
                },
                {
                    title: 'Сведения о зачислении от 30.09.2024 № 1183 (Очно-заочно. Внебюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_1183.pdf'
                },
                {
                    title: 'Сведения о зачислении от 30.09.2024 № 1184 (Заочно. Внебюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_1184.pdf'
                }
            ],
        },
        {
            title: 'Специалитет',
            links: [
                {
                    title: 'Сведения о зачислении от 29.07.2024 № 907 (Правительственная квота)',
                    href: 'https://www.art-gzhel.ru/abitur/special/doc/Prikaz_priem_907.pdf'
                },
                {
                    title: 'Сведения о зачислении от 29.07.2024 № 909 (Особая квота. Очно)',
                    href: 'https://www.art-gzhel.ru/abitur/special/doc/Prikaz_priem_909.pdf'
                },
                {
                    title: 'Сведения о зачислении от 29.07.2024 № 911 (Отдельная квота. Очно)',
                    href: 'https://www.art-gzhel.ru/abitur/special/doc/Prikaz_priem_911.pdf'
                },
                {
                    title: 'Сведения о зачислении от 07.08.2024 № 955 (Очно. Бюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/special/doc/Prikaz_priem_955.pdf'
                },
                {
                    title: 'Сведения о зачислении от 29.08.2024 № 988 (Очно. Бюджет)',
                    href: 'https://www.art-gzhel.ru/?faculty=abitur&param=special/doc/Prikaz_priem_988.pdf'
                }
            ]
        },
        {
            title: "Магистратура",
            links: [
                {
                    title: 'Сведения о зачислении от 28.08.2024 № 984 (Очно. Бюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_984.pdf'
                },
                {
                    title: 'Сведения о зачислении от 28.08.2024 № 985 (Очно-заочно. Бюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_985.pdf'
                },
                {
                    title: 'Сведения о зачислении от 28.08.2024 № 986 (Заочно. Бюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/magistr/doc/Prikaz_priem_986_new.pdf'
                },
                {
                    title: 'Сведения о зачислении от 30.08.2024 № 1002 (Очно. Внебюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_1002.pdf'
                },
                {
                    title: 'Сведения о зачислении от 30.08.2024 № 1003 (Заочно. Внебюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/bachelor/doc/Prikaz_priem_1003.pdf'
                },
                {
                    title: 'Сведения о зачислении от 30.09.2024 № 1185 (Очно. Внебюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/magistr/doc/Prikaz_priem_1185.pdf'
                },
                {
                    title: 'Сведения о зачислении от 30.09.2024 № 1186 (Заочно. Внебюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/magistr/doc/Prikaz_priem_1186.pdf'
                }
            ]
        },
        {
            title: 'Аспирантура',
            links: [
                {
                    title: 'Сведения о зачислении от 30.09.2024 № 1189 (Очно. Внебюджет)',
                    href: 'https://www.art-gzhel.ru/abitur/aspirant/doc/Prikaz_priem_1189.pdf'
                }
            ]
        },
        {
            title: "Среднее профессиональное образование (Колледж)",
            links: [
                {
                    title: 'Приказ о зачислении от 31.07.2024 № 917-ЛСО',
                    href: 'https://www.art-gzhel.ru/abitur/spo/doc/Prikaz_priem_917.pdf'
                },
                {
                    title: 'Приказ о зачислении от 02.08.2024 № 934-ЛСО (внебюджет, очно, 9 кл.)',
                    href: 'https://www.art-gzhel.ru/abitur/spo/doc/Prikaz_priem_934.pdf'
                },
                {
                    title: 'Приказ о зачислении от 02.08.2024 № 935-ЛСО (внебюджет, очно, 11 кл.)',
                    href: 'https://www.art-gzhel.ru/abitur/spo/doc/Prikaz_priem_935_new.pdf'
                },
                {
                    title: 'Приказ о зачислении от 02.08.2024 № 936-ЛСО (внебюджет, заочно)',
                    href: 'https://www.art-gzhel.ru/abitur/spo/doc/Prikaz_priem_936_new.pdf'
                },
                {
                    title: 'Приказ о зачислении от 30.08.2024 № 996-ЛСО (внебюджет, очно, 9 кл.)',
                    href: 'https://www.art-gzhel.ru/abitur/spo/doc/Prikaz_priem_996.pdf'
                },
                {
                    title: 'Приказ о зачислении от 30.08.2024 № 997-ЛСО (внебюджет, очно, 11 кл.)',
                    href: 'https://www.art-gzhel.ru/abitur/spo/doc/Prikaz_priem_997.pdf'
                },
                {
                    title: 'Приказ о зачислении от 30.08.2024 № 998-ЛСО (внебюджет, заочно)',
                    href: 'https://www.art-gzhel.ru/abitur/spo/doc/Prikaz_priem_998.pdf'
                },
                {
                    title: 'Приказ о зачислении от 30.09.2024 № 1187-ЛСО (внебюджет, очно, 9 кл.)',
                    href: 'https://www.art-gzhel.ru/abitur/spo/doc/Prikaz_priem_1187.pdf'
                },
                {
                    title: 'Приказ о зачислении от 30.09.2024 № 1188-ЛСО (внебюджет, заочно, 11 кл.)',
                    href: 'https://www.art-gzhel.ru/abitur/spo/doc/Prikaz_priem_1188.pdf'
                }
            ]
        }
    ];

    return (
        <div>
            {enrollmentInformation.map((item, index) => (
                <div key={index}>
                    <h4>{item.title}</h4>
                    <p>
                        <h5>Сведения о зачислении:</h5>
                        <ul>
                            {item.links.map((link, linkIndex) => (
                                <li key={linkIndex}>
                                    <a href={link.href} target="_blank" rel="noopener noreferrer">
                                        {link.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </p>
                    <br/>
                </div>
            ))}
        </div>
    );
}

export default App5;
